export const excludedFeatures = [
  /^\/dashboard$/,
  /^\/calendar$/,
  /^\/clients$/,
  /^\/clients\/[^/]+$/,
  /^\/messages$/,
  /^\/tasks$/,
  /^\/market-hub$/,
  /^\/admin\/forms$/,
  /^\/admin\/forms\/create-form$/,
  /^\/admin\/forms\/[^/]+$/,
  /^\/admin\/landing-page$/,
  /^\/admin\/services$/,
];
